import { useState } from "react";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";

import "./help.scss";

import HeroImage from "../../components/HeroImage/HeroImage";
import GoToTop from "../../helpers/GoToTop";
import TabsIcons from "../../components/TabsIcons/TabsIcons";
import TabsHelp from "../../components/TabsHelp/TabsHelp";
import Faq from "../../components/Faq/Faq";
import ContactUs from "../../components/ContactUs/ContactUs";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

// import { dataFaq } from "../../data/dataFaq";

function Help() {
  const [indexUtama, setIndexUtama] = useState(0);
  const [active, setActive] = useState(0);
  // const [indexReg, setIndexReg] = useState(0);
  const [open, setOpen] = useState(false);

  // Handle Tabs Utama
  const handleTab = (idx) => {
    setIndexUtama(idx);
    setActive(idx);
  };

  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/faq/all");
  // console.log('response :>> ', response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="help">
      <Seo
        title="Bantuan | Allo Bank"
        description="Dapatkan berbagai informasi mengenai produk Allobank serta layanan bantuan customer service 24 Jam. Hubungi: 080 4110 4110 / 0822 0822 4110"
        url={`www.allobank.com/help}`}
      />
      <GoToTop />
      <HeroImage source={content.data?.banner} />
      {/* Tab Icons */}
      <TabsIcons setOpen={setOpen} open={open} />

      {!open ? (
        <div className="help__tabs">
          {/* Tabs */}
          <TabsHelp
            dataTab={content.data?.faqs}
            active={active}
            handleTab={handleTab}
          />

          {/* Features */}
          <Faq dataFeatures={content.data?.faqs[indexUtama]} />
        </div>
      ) : (
        <div className="container__contact">
          <ContactUs />
        </div>
      )}

      {/* Contact */}
    </div>
  );
}

export default Help;
