import "./navitems.scss";
import NavItem from "../NavItem/NavItem";

function NavItems({ setIsOpen }) {
  return (
    <ul>
      <NavItem variant="desktop" setIsOpen={setIsOpen} />
    </ul>
  );
}

export default NavItems;
