import { motion } from "framer-motion";
import "./features.scss";
// import { ReactSVG } from "react-svg";

function Features({ dataFeatures }) {
  return (
    <div className="products__tabs__contents">
      <h2 className="products__tabs__contents__title">
        {dataFeatures?.titleFeature}
      </h2>
      <motion.div
        className="products__tabs__contents__cards"
        initial={{ y: 200 }}
        whileInView={{ y: 0 }}
        transition={{
          type: "spring",
          bounce: 0.2,
          duration: 0.3,
        }}
        viewport={{ once: true }}
      >
        {dataFeatures?.features.map((feature, idx) => (
          <div className="cards" key={idx}>
            <div className="batik">
              <img src="/assets/batikCard.svg" alt="" />
            </div>
            <div className="cards__image">
              {feature.image && (
                <img
                  src={feature.image}
                  alt={feature.featureTitle}
                  className="img__back"
                />
              )}
              {feature.imageFront && (
                <img
                  src={feature.imageFront}
                  alt={feature.featureTitle}
                  className="img__front"
                />
              )}
              {/* <ReactSVG src={feature?.image} className="icon__feature"/> */}
            </div>
            <div className="cards__title">{feature?.featureTitle}</div>
            <div className="cards__desc">{feature?.featureDesc}</div>
          </div>
        ))}
      </motion.div>
    </div>
  );
}

export default Features;
