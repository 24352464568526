import ComingSoon from "../../components/ComingSoon/ComingSoon";
import GoToTop from "../../helpers/GoToTop";
function NotFound() {
  return (
    <>
      <GoToTop />
      <ComingSoon type={"not-found"} />
    </>
  );
}

export default NotFound;
