import { useSelector } from "react-redux";
import "./tabsicons.scss";

function TabsIcons({ setOpen, open }) {
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      browse: "Browse FAQ",
      contact: "Contact Us",
    },
    Indonesia: {
      browse: "FAQ",
      contact: "Hubungi Kami",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  return (
    <div className="tabsicons">
      <div className="tabsicons__container">
        <div className="container__sekunder">
          <div
            className={`card__tab ${open === false && "active"}`}
            onClick={() => setOpen(false)}
          >
            <div className="wrapper__img">
              <img src="/assets/iconhelp1.svg" alt="" />
            </div>
            <div className="title__img">{content.browse}</div>
          </div>
          <div
            className={`card__tab ${open === true && "active"}`}
            onClick={() => setOpen(true)}
          >
            <div className="wrapper__img">
              <img src="/assets/iconhelp2.svg" alt="" />
            </div>
            <div className="title__img">{content.contact}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabsIcons;
