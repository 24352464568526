import { useState } from "react";
import { useSelector } from "react-redux";

import "./termcondition.scss";
import HeroImage from "../../components/HeroImage/HeroImage";
import Tabs from "../../components/Tabs/Tabs";
import GoToTop from "../../helpers/GoToTop";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

// import { dataTermCondition } from "../../data/dataTermCondition";

function TermCondition() {
  const [indexUtama, setIndexUtama] = useState(0);
  const [active, setActive] = useState(0);

  // Handle Tabs Utama
  const handleTab = (idx) => {
    setIndexUtama(idx);
    setActive(idx);
  };

  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/termcondition/all");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      // data: dataProducts.English,
      dataContent: response?.English,
    },
    Indonesia: {
      // data: dataProducts.Indonesia,
      dataContent: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="termcondition">
      <Seo
        title="Syarat & Ketentuan | Allo Bank"
        description="Allobank terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK). Allobank mengikuti dan menerapkan setiap persetujuan dan ketentuan umum yang mengacu pada peraturan regulator. "
        url={`www.allobank.com/term-condition}`}
      />
      <GoToTop />
      <HeroImage source={content.dataContent} />
      <div className="termcondition__tabs">
        {/* Tabs */}
        <Tabs
          dataTab={content.dataContent?.tabs}
          active={active}
          handleTab={handleTab}
        />

        {content?.dataContent?.tabs?.map((desc, idx) => (
          <div className="term__container" key={desc.id}>
            {indexUtama === idx && (
              <div
                className="term__desc"
                dangerouslySetInnerHTML={{ __html: desc.desc }}
              ></div>
            )}
          </div>
        ))}

        {/* Features */}
        {/* <Features dataFeatures={content.data[indexUtama]} /> */}

        {/* Tables */}
        {/* <Tables dataTables={content.data[indexUtama]?.tables} /> */}
      </div>
    </div>
  );
}

export default TermCondition;
