import "./tabshelp.scss";

function TabsHelp({ dataTab, active, handleTab }) {
  return (
    <div className="help__tabs__container">
      {dataTab?.map((product, idx) => (
        <button
          className={`help__tabs__container__tab  ${
            active === idx && "active"
          }`}
          key={idx}
          onClick={() => handleTab(idx)}
        >
          {product.title}
        </button>
      ))}
    </div>
  );
}

export default TabsHelp;
