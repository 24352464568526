import { toast } from "react-toastify";

// Toast Notification
export const notifyError = (str) =>
  toast(str, {
    type: "error",
    position: "bottom-left",
    autoClose: 2000,
    theme: "dark",
  });

export const notifySuccess = (str) =>
  toast(str, {
    type: "success",
    position: "bottom-left",
    autoClose: 2000,
    theme: "dark",
  });

export const notifyWarning = (str) =>
  toast(str, {
    type: "warning",
    position: "bottom-left",
    autoClose: 2000,
    theme: "dark",
  });
