import React from "react";
import { useNavigate } from "react-router-dom";
import "./card-press-release.scss";

function CardPressRelease({ dataRelease }) {
  const navigate = useNavigate();

  return (
    <div className="card__press__release">
      <div className="card__image__wrapper">
        {dataRelease.image !== "-" && (
          <img src={dataRelease.image} alt={dataRelease.titleCard} />
        )}
      </div>
      <div
        className="card__contents"
        onClick={() => navigate(`/news/${dataRelease.slug}`)}
      >
        <div className="card__title">{dataRelease.titleCard}</div>
        <div className="card__description">{dataRelease.descCard}</div>
        <div className="card__date">{dataRelease.publish_date}</div>
      </div>
    </div>
  );
}

export default CardPressRelease;
