import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Axios } from "../../config/axios";
import PressRelease from "../../components/PressRelease/PressRelease";
// import { dataAllEvents } from "../../data/dataAllEvents";
// import { dataAllNews } from "../../data/dataAllNews";
// import { dataAllPressRelease } from "../../data/dataAllPressRelease";
import GoToTop from "../../helpers/GoToTop";

import "./news-events.scss";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function NewsEvents() {
  // GET API
  const [dataNews, setDataNews] = useState(null);
  const [dataPressRelease, setDataPressRelease] = useState(null);
  const [dataEvents, setDataEvents] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await Axios.get("/api/news/news");
      setDataNews(response.data);
    };

    const fetchPressRelease = async () => {
      const response = await Axios.get("/api/news/pressrelease");
      setDataPressRelease(response.data);
    };

    const fetchEvents = async () => {
      const response = await Axios.get("/api/news/event");
      setDataEvents(response.data);
    };

    fetchNews();
    fetchPressRelease();
    fetchEvents();

    // Cleanup
    return () => {
      setDataNews(null);
      setDataPressRelease(null);
      setDataEvents(null);
    };
  }, []);

  // END GET API

  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);

  //   SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      pressRelease: dataPressRelease?.English,
      news: dataNews?.English,
      events: dataEvents?.English,
    },
    Indonesia: {
      pressRelease: dataPressRelease?.Indonesia,
      news: dataNews?.Indonesia,
      events: dataEvents?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  if (!dataPressRelease) {
    return (
      <div className="news__event">
        <Loading />
      </div>
    );
  }

  return (
    <div className="news__event">
      <Seo
        title="Berita & Acara | Allo Bank"
        description="Informasi terkini mengenai produk Allobank dan berbagai berita lain."
        url={`www.allobank.com/news}`}
      />
      <GoToTop />
      <div className="news__event__container">
        {content.events?.dataNews.length > 0 && (
          <PressRelease content={content.events} />
        )}
        {content.news?.dataNews.length > 0 && (
          <PressRelease content={content.news} />
        )}
        {content.pressRelease?.dataNews.length > 0 && (
          <PressRelease content={content.pressRelease} />
        )}
      </div>
    </div>
  );
}

export default NewsEvents;
