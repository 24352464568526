// import ComingSoon from "../../components/ComingSoon/ComingSoon";
import DownloadNow from "../../components/DownloadNow/DownloadNow";
import GoToTop from "../../helpers/GoToTop";
import Seo from "../../helpers/Seo";

function PageDownload() {
  return (
    <>
      <Seo
        title="Download Sekarang | Allo Bank"
        description="Buka rekening Allobank lewat ponsel kamu. Aktivasi cepat, aman dan simple. Tingkatkan poin untuk ditukarkan hadiah menarik setiap bulannya!"
      />
      <GoToTop />
      {/* <ComingSoon type={"download-app"} /> */}
      <DownloadNow />
    </>
  );
}

export default PageDownload;
