import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./detailnews.scss";
import { motion } from "framer-motion";
import GoToTop from "../../helpers/GoToTop";
// import { dataDetailNews } from "../../data/dataDetailNews";
import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { Axios } from "../../config/axios";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function DetailNews() {
  const params = useParams();
  // console.log("params", params);
  // GET API
  const [dataDetail, setDataDetail] = useState(null);
  const [isToast, setIsToast] = useState(false);

  useEffect(() => {
    const fetchDetail = async () => {
      const response = await Axios.get(`/api/detail/${params.slug}`);
      setDataDetail(response.data);
    };

    fetchDetail();

    // Cleanup
    return () => {
      setDataDetail(null);
    };
  }, [params.slug]);

  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  //   SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: dataDetail?.English,
      copy: "Link copied to clipboard",
    },
    Indonesia: {
      data: dataDetail?.Indonesia,
      copy: "Link telah tersalin",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Handle Copy Link
  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsToast(true);
    const timer = setTimeout(() => {
      setIsToast(false);
    }, 3000);

    return () => clearTimeout(timer);
  };

  if (!dataDetail) {
    return (
      <div className="detailnews">
        <Loading />
      </div>
    );
  }

  return (
    <motion.div
      className="detailnews"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <Seo
        title={content.data?.title.substring(0, 60)}
        description={content.data?.description.substring(0, 160)}
        url={`www.allobank.com/news/${params.slug}`}
      />

      <GoToTop />
      <div className="detailnews__container">
        <div className="container__other">
          <div className="detailnews__title">{content.data?.title}</div>
          {/* {content.data?.image !== "-" && (
            <div className="image__wrapper">
              <img src={content.data?.image} alt="" />
            </div>
          )} */}
          <div className="detailnews__datePublish">
            {content.data?.tanggal_publish}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data?.description}` }}
          ></div>

          <div className="share__icons">
            <div className="share">Share</div>
            {/* <div className="img__wrapper">
              <img src="/assets/ikon-share/share1.svg" alt="" />
            </div> */}
            <WhatsappShareButton url={window.location.href}>
              <div className="img__wrapper" title="Share whatsapp">
                <img src="/assets/ikon-share/whatsapp.svg" alt="" />
              </div>
            </WhatsappShareButton>
            <FacebookShareButton url={window.location.href}>
              <div className="img__wrapper" title="Share facebook">
                <img src="/assets/ikon-share/share2.svg" alt="" />
              </div>
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <div className="img__wrapper" title="Share twitter">
                <img src="/assets/ikon-share/share3.svg" alt="" />
              </div>
            </TwitterShareButton>
            {/* <div className="img__wrapper">
              <img src="/assets/ikon-share/share4.svg" alt="" />
            </div> */}
            <div
              className="img__wrapper"
              title="Copy link"
              onClick={handleCopyLink}
            >
              <img src="/assets/ikon-share/share5.svg" alt="" />
            </div>
            {isToast && (
              <div className="toast__wrapper">
                <img
                  src="/assets/ikon-share/share5-white.svg"
                  className="image_toast"
                  alt=""
                />
                <div className="text_toast">{content.copy}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default DetailNews;
