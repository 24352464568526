import { motion } from "framer-motion";

import "./allopoints.scss";


function AlloPoints({content}) {

  return (
    <div className="allopoints">
      <motion.div
        className="allopoints-container"
        initial={{ y: 200 }}
        whileInView={{ y: 0 }}
        transition={{
          type: "spring",
          bounce: 0.2,
          duration: 1,
        }}
        viewport={{ once: true }}
      >
        <div className="allopoints-title">
          <h2>Points</h2>{" "}
        </div>
        <div className="allopoints-cards">
          {content?.map((privilege) => (
            <div className="allopoints-card" key={privilege.id}>
              <img src={privilege.image} alt={privilege.title} />
              <h4>{privilege.title}</h4>
              <p>{privilege.desc}</p>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default AlloPoints;
