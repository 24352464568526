import { useState } from "react";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";

import "./investorrelation.scss";

import HeroImage from "../../components/HeroImage/HeroImage";
import GoToTop from "../../helpers/GoToTop";

// import { dataInvestorRelations } from "../../data/dataInvestorRelations";
// import { dataInvestorRelations } from "../../data/dataInvestorRelationsBaru";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function InvestorRelations() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/investor-relation");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  //  END REDUX

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [indexPdf, setIndexPdf] = useState(0);
  const [openData, setOpenData] = useState(false);

  // const [indexSub, setIndexSub] = useState(0);
  const [indexSub, setIndexSub] = useState(null);

  // SET OPEN WHICH
  const [mana, setMana] = useState(false);

  // Submenu
  // const [dataMenu, setDataMenu] = useState(content.data[0]?.menu);
  // const [dataMenu, setDataMenu] = useState(null);

  // Submenu
  // const [dataSubMenu, setDataSubMenu] = useState(content.data[0].submenu[0]);
  // const [dataSubMenu, setDataSubMenu] = useState(null);

  // Function to Scroll automatically
  const scrollToTop = () => {
    if (window.innerWidth < 768) {
      window.scrollTo({
        top: 1450,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({ top: 400, behavior: "smooth" });
    }
  };

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  // console.log("content.data :>> ", content);

  return (
    <div className="investorrelation">
      <Seo
        title="Hubungan Investor | Allobank"
        description="Kenali lebih dalam Allobank Digital Bank by MEGA Corpora"
        url={`www.allobank.com/about-us/investor-relations}`}
      />
      <GoToTop />
      {content.data && <HeroImage source={content.data[index]?.banner} />}
      <div className="investorrelation__contents">
        <div className="contents__container">
          {/* Left Menu */}
          <div className="left">
            <div className="left__sidebar">
              {content?.data?.map((investor, idx) => (
                <div key={idx}>
                  <div
                    className={`menus__wrapper ${
                      index === idx &&
                      investor.submenu.length === 0 &&
                      " active"
                    }`}
                    // key={investor.id}
                    onClick={() => {
                      setIndex(idx);
                      setOpen(!open);
                      // setDataMenu(investor.menu);
                      setMana(true);
                      if (investor.submenu.length === 0) {
                        scrollToTop();
                      }
                    }}
                  >
                    <div className="menu__wrapper">
                      <img src={investor.icon} alt="" className="icon" />
                      <div className="menu__title">{investor.title}</div>
                    </div>
                    {investor.submenu.length > 0 && (
                      <>
                        {open && index === investor.id - 1 ? (
                          <img
                            src="/assets/arrowDownUp.svg"
                            alt=""
                            className="arrow"
                          />
                        ) : (
                          <img
                            src="/assets/arrowDown.svg"
                            alt=""
                            className="arrow"
                          />
                        )}
                      </>
                    )}
                  </div>

                  {/* Submenu */}
                  {investor.submenu &&
                    investor.submenu?.map((sub, idx) => (
                      <div className="submenu" key={idx}>
                        {open && investor.id - 1 === index && (
                          <div
                            className={`menus__wrapper ${
                              indexSub === idx && "active"
                            }`}
                            onClick={() => {
                              // setDataSubMenu(sub);
                              setIndexSub(idx);
                              setMana(false);
                              scrollToTop();
                            }}
                          >
                            <div className="submenu__title">{sub.title}</div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>

          {/* RIGHT CONTENTS*/}
          {mana ? (
            <div className="right">
              {(content.data[index].menu.pdf?.length > 0 ||
                content.data[index].menu.content) && (
                <div className="right__sidebar">
                  <div className="header__sidebar">
                    <div className="title__sidebar">
                      {content.data[index].menu.title}
                    </div>
                  </div>
                  <div className="pdf">
                    <p>{content.data[index].menu.content}</p>
                    <div className="image__wrapper">
                      <img
                        src={content.data[index].menu.image}
                        alt=""
                        width="50%"
                      />
                    </div>
                    {content.data[index].menu.pdf.length > 0 &&
                      content.data[index].menu.pdf.map((item) => (
                        <p key={item.id}>
                          <img src="/assets/pdf.svg" alt="" />
                          <a href={item.url} target={"_blank"} rel="noreferrer">
                            {item.name}
                          </a>
                        </p>
                      ))}
                  </div>
                </div>
              )}

              {/* Jika di menu ada sidemenu */}
              {content.data[index].menu?.sideMenu?.length > 0 && (
                <div className="right">
                  {/* <p>Berhasil</p> */}
                  {content.data[index].menu?.sideMenu?.map((item, idx) => (
                    <div className="right__sidebar" key={idx}>
                      <div
                        className="header__sidebar"
                        onClick={() => {
                          setOpenData(!openData);
                          setIndexPdf(idx);
                        }}
                      >
                        <div className="title__sidebar">
                          {item.title} {/* {item.year} */}
                        </div>
                        <div className="close__sidebar">
                          <img
                            src={
                              openData && indexPdf === idx
                                ? "/assets/keyboard_arrow_down.svg"
                                : "/assets/keyboard_arrow_up.svg"
                            }
                            alt=""
                            className="arrow"
                          />
                        </div>
                      </div>
                      {openData && indexPdf === idx && (
                        <div className="pdf">
                          {item.data[0]?.pdf?.map((list, idx) => (
                            <p key={idx}>
                              <img src="/assets/pdf.svg" alt="" />
                              <a
                                href={list.link}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {list.title}
                              </a>
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="right">
              {content.data &&
                content.data[index]?.submenu[indexSub]?.data?.map(
                  (item, idx) => (
                    <div className="right__sidebar" key={idx}>
                      <div
                        className="header__sidebar"
                        onClick={() => {
                          setOpenData(!openData);
                          setIndexPdf(idx);
                        }}
                      >
                        <div className="title__sidebar">
                          {content.data[index]?.submenu[indexSub]?.title}{" "}
                          {item.year}
                        </div>
                        <div className="close__sidebar">
                          <img
                            src={
                              openData && indexPdf === idx
                                ? "/assets/keyboard_arrow_down.svg"
                                : "/assets/keyboard_arrow_up.svg"
                            }
                            alt=""
                            className="arrow"
                          />
                        </div>
                      </div>
                      {openData && indexPdf === idx && (
                        <div className="pdf">
                          {item.pdf.map((list, idx) => (
                            <p key={idx}>
                              <img src="/assets/pdf.svg" alt="" />
                              <a
                                href={list.link}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {list.title}
                              </a>
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InvestorRelations;
