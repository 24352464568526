import { useRef } from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";
import "./registers.scss";

import { ReactComponent as Prev } from "../../assets/prev.svg";

function Registers({
  dataRegisters,
  activeSekunder,
  handleTabSekunder,
  indexSekunder,
}) {
  // Event mousemove
  const cardsRef = useRef();
  const wrapperRef = useRef();

  const handleMouseMove = (e) => {
    const { clientX } = e;
    // console.log("clientX :>> ", clientX);
    cardsRef.current.style.transform = `translateX(-${clientX}px)`;
    cardsRef.current.style.transition = `0.7s ease`;
  };

  const handleMouseLeave = () => {
    cardsRef.current.style.transform = `translateX(0)`;
  };

  const handleArrow = (direction) => {
    if (direction === "l") {
      wrapperRef.current.scrollLeft -= 300;
    }

    if (direction === "r") {
      wrapperRef.current.scrollLeft += 300;
    }
  };

  useEffect(() => {
    cardsRef.current.addEventListener("click", handleMouseMove);
    wrapperRef.current.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      window.removeEventListener("click", handleMouseMove);
      window.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  // End Event mousemove

  return (
    <motion.div
      className="products__registers"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <h2 className="products__registers__title">
        {dataRegisters?.titleRegister}
        <br />
        {dataRegisters?.titleRegister2}
      </h2>
      {/* Tabs 2 */}
      <div
        className="tabs__two"
        style={{ height: "4.55rem", overflow: "hidden" }}
      >
        <div
          className={`products__registers__container ${
            dataRegisters?.title === "Allo Pay & Allo Pay+" && "center"
          }`}
        >
          {dataRegisters?.registers?.map(
            (product, index) =>
              dataRegisters?.registers.length > 1 && (
                <button
                  className={`products__registers__container__tab  ${
                    activeSekunder === index && "active"
                  }`}
                  key={product.id}
                  onClick={() => handleTabSekunder(index)}
                >
                  {product.title}
                </button>
              )
          )}
        </div>
      </div>
      {/* Cards Register */}
      <div className="products__registers__cards">
        <div
          className="arrow"
          style={{ left: "1.5rem" }}
          onClick={() => handleArrow("l")}
        >
          <Prev className="prev__button" />
        </div>
        <div className="wripper__slider" ref={wrapperRef}>
          <div
            ref={cardsRef}
            className="cards__slider"
            // style={{ transform: `translateX(${-7 * indexReg}rem)` }}
          >
            {dataRegisters?.registers[indexSekunder]?.contents?.map(
              (reg, index) => (
                <div className="card__wrapper" key={index}>
                  <div className="image__container">
                    <img src={reg.image} alt="" />
                    {/* <div className="card__number">{reg.no}</div> */}
                  </div>
                  <div className="card__caption">{reg.caption}</div>
                </div>
              )
            )}
          </div>
        </div>
        <div
          className="arrow"
          style={{ right: "1.5rem" }}
          onClick={() => handleArrow("r")}
        >
          <Prev className="next__button" />
        </div>
      </div>
    </motion.div>
  );
}

export default Registers;
