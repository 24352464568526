export const dataCsr = {
  Indonesia: {
    // title: "Corporate Social Responsibility",
    csr: `
    <p>
    Kebijakan Allo Bank dalam menerapkan Tanggung Jawab Sosial Perusahaan (Corporate Social Responsibility atau “CSR”) mengacu pada prinsip keberlanjutan (sustainability) sebagai bentuk komitmen Bank untuk mendukung misi Sustainable Development Goals (SDGs) dan membangun hubungan harmonis dengan masyarakat, lingkungan dan seluruh pemangku kepentingan lainnya melalui perilaku yang transparan dan beretika. 
    </p>
    
    <p>
    CSR juga merupakan bentuk komitmen Allo Bank kepada lingkungan dengan tujuan untuk berperan serta dalam pembangunan berkelanjutan selaras dengan prinsip Triple Bottom Line yaitu People, Planet, Profit (3P) serta memberikan nilai tambah kepada semua pemangku kepentingan, termasuk internal perusahaan guna mendukung pertumbuhan Bank.
    </p>

    <p>
    Program CSR yang dilakukan oleh Bank, di bawah branding Allo Bank Pelita, bertujuan untuk:
    </p>

    <p>
    <ol type='a'>
      <li>Membangun citra dan reputasi Bank yang dapat meningkatkan kepercayaan kepada Allo Bank</li>
      <li>Membangun hubungan yang harmonis dengan segenap pemangku kepentingan. </li>
      <li>Menumbuhkan positive value karyawan terhadap Allo Bank. </li>
      <li>Konsisten dengan pembangunan berkelanjutan (sustainable development) dan kesejahteraan masyarakat.</li>
      <li>Mematuhi peraturan dan ketentuan yang berlaku.</li>
    </ol>
    </p>

    <p>
    Poin-poin di atas mewakili kepercayaan Allo Bank bahwa inisiatif CSR bukanlah tindakan amal sekali saja, tapi merupakan program yang strategis dan berkelanjutan yang dirancang untuk meningkatkan kesejahteraan masyarakat.
    </p>
    <p>
    Berkelanjutan berarti bahwa inisiatif CSR Allo Bank seyogyanya dirancang sedemikian rupa untuk membantu memenuhi kebutuhan dari sebuah komunitas masyarakat sebagai penerima manfaat dan mengembangkannya sedemikian rupa sehingga program tersebut dapat berjalan secara independen di masa depan. 
    </p>
    <p>
    Informasi lebih lanjut atas program CSR yang telah dilakukan Bank juga dapat dibaca di dalam Laporan Tahunan dan Laporan Keberlanjutan Bank dan juga dalam bagian Berita di website ini.  
    </p>
    
    `
  },
  English: {
    // title: "Corporate Social Responsibility",
    csr: `
    <p>
    Allo Bank's Policy in implementing Corporate Social Responsibility (CSR) refers to the principle of sustainability as a form of the Bank's commitment to supporting the Sustainable Development Goals (SDGs) mission and building harmonious relationships with the society, environment, and all other stakeholders through transparent and ethical behaviors.
    </p>
    
    <p>
    CSR is also a manifestation of Allo Bank's commitment to the environment with the aim of participating in sustainable development in line with the Triple Bottom Line principle, namely People, Planet, Profit (3P) and providing added value to all stakeholders, including internal ones to support the Bank's growth.
    </p>
    <p>
    CSR programs carried out by the Bank, under Allo Bank Pelita branding, aim to:
    </p>
    <p>
    <ol type="a">
      <li>Building the Bank's image and reputation which can increase trust in Allo Bank</li>
      <li>Building harmonious relationships with all stakeholders. </li>
      <li>Growing positive employee values towards Allo Bank.</li>
      <li>Consistent with sustainable development and community wellbeing.</li>
      <li>Comply with applicable rules and regulations.</li>
    </ol>
    </p>
    <p>
    The points above represent Allo Bank's belief that CSR initiatives are not just one-off acts of charity, but are strategic and sustainable programs designed to improve community wellbeing.
    </p>
    <p>
    Sustainable means that Allo Bank's CSR initiatives should be designed in such a way as to help meet the needs of the communities as beneficiaries and develop them in such a way that the program can run independently in the future. 
    </p>
    <p>
    Further information on the CSR programs carried out by the Bank can also be read in the Bank's Annual and Sustainability Report and also in the News section of this website.
    </p>
    
    `
  },
};
