import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Axios } from "../../config/axios";
import { dataCsr } from "../../data/dataCsr";
import GoToTop from "../../helpers/GoToTop";

import "./csr.scss";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";
import CustomerRelation from "../CustomerRelation/CustomerRelation";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

function CsrOld() {
  // GET API
  const [csr, setCsr] = useState(null);
  console.log(csr, "csr");
  console.log(dataCsr, "data All Csr");

  useEffect(() => {
    const fetchNews = async () => {
      // const response = await Axios.get("/api/news/news");
      // if (response) {
      //   setCsr(response);
      // }
      setCsr(dataCsr)
    };

    fetchNews();

    // Cleanup
    return () => {
      setCsr(null);
    };
  }, []);

  // END GET API

  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);

  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: dataCsr?.English,
    Indonesia: dataCsr?.Indonesia,
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  if (!csr) {
    return (
      <div className="news__event">
        <Loading />
      </div>
    );
  }

  return (
    <div className="news__event">
      <Seo
        title="CSR | Allo Bank"
        description="PT Allobank Tbk melakukan berbagai kegiatan CSR (Corporate Social Responbility) sebagai bentuk tanggung jawab terhadap sosial dan lingkungan. Wujud peduli kepada masyarakat Indonesia."
        url={`www.allobank.com/about-us/csr}`}
      />
      <GoToTop />
      <div className="detailnews__container">
        <div className="container__other">
          <div className="detailnews__title">Allo Bank Corporate Social Responsibility</div>
          {/* {content.data?.image !== "-" && (
            <div className="image__wrapper">
              <img src={content.data?.image} alt="" />
            </div>
          )} */}
          {/* <div className="detailnews__datePublish">
            {content.data?.tanggal_publish}
          </div> */}
          <div
            dangerouslySetInnerHTML={{ __html: `${content.csr}` }}
          ></div>
        </div>
      </div>
      {/* {<ComingSoon type="csr" />} */}
    </div>
  );
}

export default CsrOld;
