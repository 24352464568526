import { useEffect, useState } from "react";
import { Axios } from "../config/axios";

const useFetch = (url) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      const fetchData = async () => {
        const data = await Axios.get(url);
        setResponse(data.data);
        setLoading(false);
      };
      fetchData();
    } catch (error) {
      setError(error);
      setLoading(false);
    }

    // cleanup
    return () => {
      setResponse(null);
      setError(null);
      setLoading(false);
    };
  }, [url]);

  return { response, error, loading };
};
export default useFetch;
