import React from "react";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import GoToTop from "../../helpers/GoToTop";

function NewsSoon() {
  return (
    <>
      <GoToTop />
      <ComingSoon type={"news"} />
    </>
  );
}

export default NewsSoon;
