import "./cardpromotionbig.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import baseUrl from "../../helpers/baseUrl";


function CardPromotionBig(props) {
  const navigate = useNavigate();
  const state = useSelector((state) => state.translateReducer.bahasa);

  // SETUP CONTENTS BASED ON LANGUAGE
  let validUntil = {
    English: "Valid Until",
    Indonesia: "Berlaku Hingga",
  };
  let content = ''

  if (state === "Indonesia") {
    validUntil = validUntil.Indonesia;
  } else {
    validUntil = validUntil.English;
  }
  return (
    <div className="cardpromotionbig">
      <div className="image__wrapper">
        {props.Promo && <img src={props.Promo.image} alt={props.image_alt_text} />}
      </div>
      <div className="cardpromotion__details">
        {props.Promo && (
          <div className="expired__date">
            {validUntil} <span>{props.Promo.expired}</span>{" "}
          </div>
        )}
        <Link
          className="cardpromotion__title"
          to={`/promo/${props.Promo?.slug}`}
          // onClick={() => navigate(`/promo/${props.Promo.slug}`)}
        >
          {props.Promo && props.Promo.titleCard}
        </Link>
        <div className="cardpromotion__desc">
          {props.Promo && props.Promo.descriptionCard}
        </div>
      </div>
    </div>
  );
}

export default CardPromotionBig;
