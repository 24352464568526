import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function GoToTop() {
  const routePath = useNavigate();
  const onTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return null;
}
