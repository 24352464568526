let languageStoredInLocalStorage = localStorage.getItem("language");

const initialState = {
  bahasa: languageStoredInLocalStorage
    ? languageStoredInLocalStorage
    : "Indonesia",
  // Jika direfresh tidak balik lagi!
};

const translateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "CHANGE":
      localStorage.setItem("language", payload);
      return {
        ...state,
        bahasa: payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default translateReducer;
