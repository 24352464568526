import { useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Axios } from "../../config/axios";

import "./contactus.scss";
import "react-toastify/dist/ReactToastify.css";

import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../helpers/notify";

function ContactUs() {
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "Send us a message",
      titleContact: "Contact Us",
      buttonSubmit: "Submit",
      name: "Name",
      email: "Email",
      message: "Message",
      phone: "Phone Number",
      problem: "Problem",
    },
    Indonesia: {
      title: "Kirim Pesan",
      titleContact: "Hubungi Kami",
      buttonSubmit: "Kirim",
      name: "Nama",
      email: "Email",
      message: "Pesan",
      phone: "Nomor Ponsel",
      problem: "Masalah",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // SUBMIT
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [problem, setProblem] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.length > 5) {
      if (phone.length > 10 && phone.length < 15 && isNaN(phone) === false) {
        if (email.length > 7 && email.includes("@")) {
          if (problem.length > 6 && problem.length < 30) {
            if (message.length > 25) {
              postData();
            } else {
              notifyWarning("Panjang pesan minimal 25 karakter");
            }
          } else {
            notifyWarning("Problem panjang karakter minimal 6 - 30 karakter");
          }
        } else {
          notifyWarning("Email harus yang valid");
        }
      } else {
        notifyWarning("Nomor ponsel diisi yang valid (10-15 digit)");
      }
    } else {
      notifyWarning("Minimal panjang nama 5 karakter");
    }
  };

  const postData = async () => {
    const data = {
      name: name,
      email: email,
      phone_number: phone,
      problem: problem,
      message: message,
    };

    try {
      const response = await Axios.post("/api/contactus/report", data);
      // console.log("response :>> ", response.data.msg);
      notifySuccess(response.data.msg);

      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setProblem("");
    } catch (error) {
      // console.log("error :>> ", error.response);
      notifyError("Ada kesalahan pada server. Data gagal dikirim");
    }
  };

  return (
    <motion.div
      className="contactus"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <div className="message">
        <div className="batik">
          <img src="/assets/batikCard.svg" alt="" />
        </div>
        <div className="title">{content.title}</div>
        <form onSubmit={handleSubmit}>
          <div className="group__control">
            <input
              type="text"
              placeholder={content.name}
              onChange={(e) => setName(e.target.value)}
              value={name}
              className={
                name.length === 0
                  ? "netral"
                  : name.length > 5
                  ? "success"
                  : "error"
              }
            />
            <input
              type="text"
              placeholder={content.phone}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              className={
                phone.length === 0
                  ? "netral"
                  : phone.length > 10 &&
                    phone.length < 15 &&
                    isNaN(phone) === false
                  ? "success"
                  : "error"
              }
            />
          </div>
          <div className="group__control">
            <input
              type="text"
              placeholder={content.email}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className={
                email.length === 0
                  ? "netral"
                  : email.length > 7 && email.includes("@")
                  ? "success"
                  : "error"
              }
            />
            <input
              type="text"
              placeholder={content.problem}
              onChange={(e) => setProblem(e.target.value)}
              value={problem}
              className={
                problem.length === 0
                  ? "netral"
                  : problem.length > 5 && problem.length <= 30
                  ? "success"
                  : "error"
              }
            />
          </div>
          <div className="group__control">
            <textarea
              name=""
              id=""
              placeholder={content.message}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              className={
                message.length === 0
                  ? "netral"
                  : message.length > 25
                  ? "success"
                  : "error"
              }
            ></textarea>
          </div>
          <div className="btn__wrapper">
            <button type="submit" className="btn__contact">
              {content.buttonSubmit}
            </button>
          </div>
        </form>
      </div>
      <div className="contact">
        <div className="batik__contact">
          <img src="/assets/supergrafic-kontak.png" alt="" />
        </div>
        <div className="title">{content.titleContact}</div>
        <div className="kontak">
          <img src="/assets/call.svg" alt="" />
          <span>080 4110 4110</span>
        </div>
        <div className="kontak">
          <img src="/assets/whatsapp.svg" alt="" />
          <span>0822 0822 4110</span>
        </div>
        <div className="kontak">
          <img src="/assets/email.svg" alt="" />
          <span>allocare@allobank.com</span>
        </div>
        {/* <div className="center">
          <div className="btn__wrapper">
            <button type="submit" className="btn__contact">
              Chat Now
            </button>
          </div>
        </div> */}
      </div>

      {/* Notify */}
      <ToastContainer />
    </motion.div>
  );
}

export default ContactUs;
