import "./profilesstory.scss";

function ProfilesStory({ data }) {
  // console.log("data :>> ", data);
  return (
    <div className="profiles__story">
      <div className="profiles__story__container">
        <div className="story__image">
          <img src={data?.imageP1} alt="" />
        </div>
        <div className="story__content">
          <h2 className="story__content__title">{data?.titleProfil}</h2>
          <div
            className="story__content__desc"
            dangerouslySetInnerHTML={{ __html: data?.p1 }}
          >
            {/* <p>{data?.p1}</p>
            <p>{data?.p2}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilesStory;
