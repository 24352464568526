import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import "./detail-ramadhan.scss";

import GoToTop from "../../helpers/GoToTop";
// import { dataDetailPromotion } from "../../data/dataDetailPromotion";
import Loading from "../../helpers/Loading";
import useFetch from "../../hooks/useFetch";
import { getRamadhanDetail } from "../../data/dataRamadhan";

function DetailRamadhan() {
  const params = useParams();
  const [isToast, setIsToast] = useState(false);

  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch(`/api/promo/category/${params.category}/${params.slug}`);
  // const response = getRamadhanDetail(type,params.slug);
  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);

  //   SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
      mechanisme: "Mechanism",
      term: "Terms and Conditions",
      copy: "Link copied to clipboard",
    },
    Indonesia: {
      data: response?.Indonesia,
      mechanisme: "Mekanisme",
      term: "Syarat dan Ketentuan",
      copy: "Link telah tersalin",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }
  // let today = new Date()
  // today.setHours(0)
  // today.setMinutes(0)
  // today.setSeconds(0)
  // let publish_date = new Date(response.publish_date)
  // let expired_date = new Date(response.expired)
  // expired_date.setHours(23)
  // expired_date.setMinutes(59)
  // expired_date.setSeconds(59)
  // let grayscale = publish_date >= today || expired_date <= today  ? 'expired' : ''

  // Handle Copy Link
  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsToast(true);
    const timer = setTimeout(() => {
      setIsToast(false);
    }, 3000);

    return () => clearTimeout(timer);
  };


  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return (<Loading />);
  }

  return (
    <motion.div
      className="detailpromoramadhan"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <GoToTop />
      <div className="detailpromoramadhan__container">
        <div className="container__other">
          <div className="detailpromoramadhan__title">{content.data?.title}</div>
          <div className={`image__wrapper`}>
            <img src={content.data?.image} alt={content.data?.image_alt_text} />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data?.description}` }}
          ></div>

          <div className="subtitle">{content.mechanisme}</div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data?.mechanisme}` }}
          ></div>

          <div className="subtitle">{content.term}</div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data?.conditions}` }}
          ></div>

          <div className="share__icons">
            <div className="share">Share</div>
            {/* <div className="img__wrapper">
              <img src="/assets/ikon-share/share1.svg" alt="" />
            </div>
            <FacebookShareButton url={`www.allobank.com/${params.slug}`}>
              <div className="img__wrapper">
                <img src="/assets/ikon-share/share2.svg" alt="" />
              </div>
            </FacebookShareButton>
            <TwitterShareButton url={`www.allobank.com/${params.slug}`}>
              <div className="img__wrapper">
                <img src="/assets/ikon-share/share3.svg" alt="" />
              </div>
            </TwitterShareButton>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share4.svg" alt="" />
            </div>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share5.svg" alt="" />
            </div> */}
            <WhatsappShareButton url={window.location.href}>
              <div className="img__wrapper" title="Share whatsapp">
                <img src="/assets/ikon-share/whatsapp.svg" alt="" />
              </div>
            </WhatsappShareButton>
            <FacebookShareButton url={window.location.href}>
              <div className="img__wrapper" title="Share facebook">
                <img src="/assets/ikon-share/share2.svg" alt="" />
              </div>
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <div className="img__wrapper" title="Share twitter">
                <img src="/assets/ikon-share/share3.svg" alt="" />
              </div>
            </TwitterShareButton>
            {/* <div className="img__wrapper">
              <img src="/assets/ikon-share/share4.svg" alt="" />
            </div> */}
            <div
              className="img__wrapper"
              title="Copy link"
              onClick={handleCopyLink}
            >
              <img src="/assets/ikon-share/share5.svg" alt="" />
            </div>
            {isToast && (
              <div className="toast__wrapper">
                <img
                  src="/assets/ikon-share/share5-white.svg"
                  className="image_toast"
                  alt=""
                />
                <div className="text_toast">{content.copy}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default DetailRamadhan;
