import {
  Navigate,
  Route,
  Routes,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./app.scss";

// import HomePage from "./pages/HomePage/HomePage";
import HomePage from "./pages/homepage/HomePage";
import Products from "./pages/Products/Products";
import Help from "./pages/Help/Help";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Pay from "./pages/Pay/Pay";
import TopUp from "./pages/TopUp/TopUp";
import Transfer from "./pages/Transfer/Transfer";
import CashOut from "./pages/CashOut/CashOut";
import BillPayment from "./pages/BillPayment/BillPayment";
// import GivePoints from "./pages/GivePoints/GivePoints";
import ManagementCommitte from "./pages/ManagementCommitte/ManagementCommitte";
import Profiles from "./pages/Profiles/Profiles";
import InvestorRelations from "./pages/InvestorRelations/InvestorRelations";
// import ArticleAssociation from "./pages/ArticleAssociation/ArticleAssociation";
import Benefit from "./pages/Benefit/Benefit";
import Promo from "./pages/Promo/Promo";
import DetailPromo from "./pages/DetailPromo/DetailPromo";
import PageDownload from "./pages/PageDownload/PageDownload";
// import PromoSoon from "./pages/PromoSoon/PromoSoon";
import Privacy from "./pages/Privacy/Privacy";
import TermCondition from "./pages/TermCondition/TermCondition";
import RateLimitFees from "./pages/RateLimitFees/RateLimitFees";
import NewsEvents from "./pages/NewsEvents/NewsEvents";
import NotFound from "./pages/NotFound/NotFound";
import DetailNews from "./pages/DetailNews/DetailNews";
import NewsSoon from "./pages/NewsSoon/NewsSoon";
import CorporateGovernance from "./pages/CorporateGovernance/CorporateGovernance";
import Csr from "./pages/Csr/Csr";
import RamadhanEvents from "./pages/RamadhanEvents/ramadhan-events";
import RamadhanAll from "./pages/RamadhanAll/ramadhan-all";
import DetailRamadhan from "./pages/DetailRamadhan/DetailRamadhan";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { translateAction } from "./store/actions/translateAction";
import RedirectLang from "./helpers/redirectLang";
import DetailArticleCsr from "./pages/DetailArticleCsr/DetailArticleCsr";
import CsrArticles from "./pages/CsrArticles/CsrArticles";
import Devisa from "./components/devisa/Devisa";
import CsrOld from "./pages/Csr/Csr";

function App() {
  const state = useSelector((state) => state.translateReducer.bahasa);
  let lang = state === "English" ? "en" : "id";
  return (
    <div className="app">
      <Helmet>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        {/* <Route path="/:lang" element={<HomePage />} /> */}
        {/* Product */}
        <Route path="products" element={<Products />} />
        {/* <Route path="/:lang/products" element={<Products />} /> */}
        {/* Services */}
        <Route path="services" element={<Pay />} />
        <Route path="services/pay" element={<Pay />} />
        <Route path="services/top-up" element={<TopUp />} />
        <Route path="services/transfer" element={<Transfer />} />
        <Route path="services/cash-out" element={<CashOut />} />
        <Route path="services/bill-payment" element={<BillPayment />} />
        {/* <Route path="services/send-gifts" element={<GivePoints />} /> */}
        {/* About Us */}
        <Route path="about-us" element={<Profiles />} />
        <Route path="about-us/profiles" element={<Profiles />} />
        <Route
          path="about-us/investor-relations"
          element={<InvestorRelations />}
        />
        <Route
          path="about-us/management-committee"
          element={<ManagementCommitte />}
        />
        <Route
          path="about-us/corporate-governance"
          element={<CorporateGovernance />}
        />
        <Route path="about-us/csr" element={<CsrOld />} />

        {/* <Route path="about-us/csr" element={<Csr />} />
        <Route path="about-us/csr/articles" element={<CsrArticles />} />
        <Route path="about-us/csr/articles/:slug" element={<DetailArticleCsr />} /> */}
        {/* <Route path="about-us/csr/:slug" element={<DetailNews />} /> */}
        {/* <Route
          path="about-us/article-of-association"
          element={<ArticleAssociation />}
        /> */}
        {/* Benefit */}
        <Route path="benefit" element={<Benefit />} />
        {/* Promo */}
        <Route path="promo" element={<Promo />} />
        <Route path="promo/:slug" element={<DetailPromo />} />
        {/* <Route path="promo-soon" element={<PromoSoon />} /> */}
        {/* News */}
        <Route path="news" element={<NewsEvents />} />
        <Route path="news/:slug" element={<DetailNews />} />
        <Route path="news-soon" element={<NewsSoon />} />
        {/* Ramadhan Events */}
        {/* <Route path="promo-this-month" element={<RamadhanEvents />} />
        <Route path="promo-this-month/:category" element={<RamadhanAll />} /> */}
        <Route
          path="promo-this-month/:category/:slug"
          element={<DetailRamadhan />}
        />
        {/* Help */}
        <Route path="help" element={<Help />} />
        <Route path="download" element={<PageDownload />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="term-condition" element={<TermCondition />} />
        <Route path="rate-limit-fees" element={<RateLimitFees />} />
        {/* <Route path="Devisa" element={<Devisa />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
