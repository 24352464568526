import { motion } from "framer-motion";

import "./couponts.scss";

function Couponts({ content }) {
  return (
    <motion.div
      className="couponts__member"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <div className="couponts__member__container">
        <div className="member__image">
          <img src={content?.image} alt="" />
        </div>
        <div className="member__content">
          <div className="member__content__title">
            <h2>{content?.title}</h2>
          </div>
          <div className="member__content__desc">
            <p>{content?.desc}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Couponts;
