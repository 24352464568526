export const medsos = [
  {
    id: "1",
    url: "/assets/instagram.svg",
    link: "https://www.instagram.com/allobank/",
  },
  {
    id: "2",
    url: "/assets/facebook.svg",
    link: "https://facebook.com/AlloBankID",
  },
  {
    id: "3",
    url: "/assets/linkedin.svg",
    link: "https://www.linkedin.com/company/allo-bank/",
  },
  {
    id: "4",
    url: "/assets/youtube.svg",
    link: "https://www.youtube.com/channel/UCCnAbRQY5SxDTo7Es7Vmr0A",
  },
  {
    id: "5",
    url: "/assets/tiktok.svg",
    link: "https://www.tiktok.com/@allobankid?",
  },
  {
    id: "6",
    url: "/assets/twitter.svg",
    link: "https://twitter.com/AlloBankID",
  },
];
