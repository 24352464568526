import { useRef } from "react";
import "./popupmens.scss";

function PopUpMens({ setPopUp, popUp }) {
  // SET CLOSE
  const contentRef = useRef();
  const handleClose = () => {
    contentRef.current.style.animation = "hidden 1s";
    setTimeout(() => {
      setPopUp(null);
    }, 1000);
  };

  return (
    <div className="popup">
      <div className="container-popup" ref={contentRef}>
        <div className="close-popup" onClick={handleClose}>
          x
        </div>
        <div className="left">
          <div className="img-popup">
            <img src={popUp.image} alt="" />
          </div>
        </div>
        <div className="desc-content-popup">
          <p>{popUp.profil}</p>
        </div>
      </div>
    </div>
  );
}

export default PopUpMens;
