import React from "react";
import "./loading.scss";

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading__container">
        <img src="/assets/loading/hp.png" className="handphone" alt="" />
        <img src="/assets/loading/kiri.png" className="kiri" alt="" />
        <img src="/assets/loading/tengah.png" className="tengah" alt="" />
      </div>
    </div>
  );
};

export default Loading;
