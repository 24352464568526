import { useEffect, useRef } from "react";
import "./menumobile.scss";
import { ReactComponent as Humberger } from "../../assets/hamburgerfix.svg";
import { ReactComponent as Close } from "../../assets/closefix.svg";
import NavItem from "../NavItem/NavItem";

function MenuMobile({ setIsOpen, isOpen }) {
  // const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef();

  let timer;
  const handleClose = () => {
    modalRef.current.style.animation = "slideOut 1s";
    timer = setTimeout(() => {
      setIsOpen(false);
    }, 1000);
  };

  // Refresh event
  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return (
    <div className="menu-mobile">
      {/* <!-- Hamberger Menu --> */}
      {!isOpen && (
        <Humberger className="hamburger" onClick={() => setIsOpen(true)} />
      )}

      {isOpen && <Close className="hamburger" onClick={handleClose} />}

      {isOpen && (
        <div className="modal-menu" ref={modalRef}>
          <NavItem variant="mobile" setIsOpen={setIsOpen} />
        </div>
      )}
    </div>
  );
}

export default MenuMobile;
