import { useState } from "react";
import { useSelector } from "react-redux";

import "./milestone.scss";
// import { dataMilestone } from "../../data/dataMilestone";
import Loading from "../../helpers/Loading";
import useFetch from "../../hooks/useFetch";

function Milestone() {
  const [open, setOpen] = useState(false);

  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/milestone");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "Milestone",
      view: "View All Milestone",
      data: response?.English,
    },
    Indonesia: {
      title: "Perjalanan Kami",
      view: "View All Milestone",
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div
      className={`milestone`}
      style={{ height: `${open === false ? "59.375rem" : "auto"}` }}
    >
      <div className="image__wrapper">
        <img src="/assets/grafic-milestone.png" alt="" />
      </div>
      {!open && (
        <div className="cover">
          <button className="tab" onClick={() => setOpen(true)}>
            {content.view}
          </button>
        </div>
      )}
      <h2 className="milestone__title">{content.title}</h2>
      {content.data?.map((mil, idx) => (
        <div className="milestone__wrapper" key={idx}>
          <div className="milestone__year">{mil.tahun}</div>
          <div className="limit"></div>
          <div className="milestone__desc">
            {mil.title}
            {mil.image !== "-" && <img src={mil.image} alt="" />}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Milestone;
