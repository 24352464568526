import "./cardpromotion.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import baseUrl from "../../helpers/baseUrl";


function CardPromotion({ dataPromo }) {
  const navigate = useNavigate();
  const state = useSelector((state) => state.translateReducer.bahasa);

  // SETUP CONTENTS BASED ON LANGUAGE
  let validUntil = {
    English: "Valid Until",
    Indonesia: "Berlaku Hingga",
  };
  let content = ''

  if (state === "Indonesia") {
    validUntil = validUntil.Indonesia;
  } else {
    validUntil = validUntil.English;
  }
  

  return (
    <div className="cardpromotion">
      <div className="image__wrapper">
        <img src={dataPromo.image} alt={dataPromo.image_alt_text} />
      </div>
      <div className="cardpromotion__details">
        <div className="expired__date">
          {validUntil} <span>{dataPromo.expired}</span>{" "}
        </div>
        <Link
          className="cardpromotion__title"
          to={`/promo/${dataPromo?.slug}`}
          // onClick={() => navigate(`/promo/${dataPromo.slug}`)}
        >
          {dataPromo.titleCard}
        </Link>
        <div className="cardpromotion__desc">{dataPromo.descriptionCard}</div>
      </div>
    </div>
  );
}

export default CardPromotion;
