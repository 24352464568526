import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import TagManager from 'react-gtm-module-nonce-patch'

import store from "./store";
import App from "./App";

// const tagManagerArgs = {
//   gtmId : 'GTM-N66NTFQ'
// }

// TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
